import "./App.css";
import HeadAbout from "./HeadAbout.js";
import { Posts } from "./Posts.js";
import { useState } from "react";

function App() {
    const [page, setPage] = useState(0);
    return (
        <div className="App">
            <main>
                {page === 0 ? (
                    <HeadAbout />
                ) : (
                    <div className="PageTitle">Page {page}</div>
                )}
                <div className="MainContent">
                    <Posts page={page} setPage={setPage} />
                </div>
            </main>
            <footer> &copy; 2024 Kirill Mikhailov</footer>
        </div>
    );
}

export default App;
